define("ember-table/-private/utils/observer", ["exports", "@ember/debug", "@ember/object", "@ember/object/observers"], function (_exports, _debug, _object, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeObserver = _exports.observer = _exports.addObserver = void 0;
  // eslint-disable-next-line no-restricted-imports

  // eslint-disable-next-line no-restricted-imports

  const USE_ASYNC_OBSERVERS = true;
  function asyncObserver() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    let fn = args.pop();
    let dependentKeys = args;
    let sync = false;
    return (0, _object.observer)({
      dependentKeys,
      fn,
      sync
    });
  }
  function asyncAddObserver() {
    let obj, path, target, method;
    let sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && (0, _debug.assert)("Expected 3 or 4 args for addObserver, got ".concat(arguments.length), arguments.length === 3 || arguments.length === 4));
    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else if (arguments.length === 4) {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }
    return (0, _observers.addObserver)(obj, path, target, method, sync);
  }
  function asyncRemoveObserver() {
    let obj, path, target, method;
    let sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && (0, _debug.assert)("Expected 3 or 4 args for addObserver, got ".concat(arguments.length), arguments.length === 3 || arguments.length === 4));
    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }
    return (0, _observers.removeObserver)(obj, path, target, method, sync);
  }
  const observer = _exports.observer = USE_ASYNC_OBSERVERS ? asyncObserver : _object.observer;
  const addObserver = _exports.addObserver = USE_ASYNC_OBSERVERS ? asyncAddObserver : _observers.addObserver;
  const removeObserver = _exports.removeObserver = _observers.removeObserver ? asyncRemoveObserver : _observers.removeObserver;
});