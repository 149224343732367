define("ember-root-url/services/root-url", ["exports", "@ember/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RootUrlService extends _service.default {
    build(relativeURL) {
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return "".concat(ENV.rootURL).concat(relativeURL.replace(/^\//, ''));
    }
  }
  _exports.default = RootUrlService;
});