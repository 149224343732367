define("ember-math-helpers/helpers/gcd", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gcd = gcd;
  /**
   * Returns the greatest positive integer that divides each of two integers
   *
   * ```hbs
   * {{gcd a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The GCD of the two numbers passed
   */
  function gcd(_ref) {
    let [number1 = 0, number2 = 0] = _ref;
    const a = Math.abs(number1);
    const b = Math.abs(number2);
    if (a === 0) {
      return b;
    }
    if (b === 0) {
      return a;
    }
    return gcd([b, a % b]);
  }
  var _default = _exports.default = (0, _helper.helper)(gcd);
});