define("ember-concurrency/-private/task-decorators", ["exports", "@ember/object", "ember-concurrency/-private/task-factory", "ember-concurrency/-private/utils"], function (_exports, _object, _taskFactory, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = _exports.lastValue = _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  function taskFromPropertyDescriptor(target, key, descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let {
      initializer,
      get,
      value
    } = descriptor;
    let taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = "".concat(key, " (task)");
    let tasks = new WeakMap();
    let options = params[0] || {};
    let factory = new _taskFactory.TaskFactory(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get() {
        let task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(target, key, _descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let taskGroups = new WeakMap();
    let options = params[0] || {};
    let factory = new _taskFactory.TaskGroupFactory(key, null, options);
    return {
      get() {
        let task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    let [target, key, desc] = possibleDesc;
    return possibleDesc.length === 3 && typeof target === 'object' && target !== null && typeof key === 'string' && (typeof desc === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }

  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn(...params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            desc[_key2] = arguments[_key2];
          }
          return descriptorFn(...desc, params);
        };
      }
    };
  }
  function createDecorator(fn) {
    let baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return decoratorWithParams(function (target, key, descriptor) {
      let [userOptions] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      let mergedOptions = Object.assign({}, {
        ...baseOptions,
        ...userOptions
      });
      return fn(target, key, descriptor, [mergedOptions]);
    });
  }
  const lastValue = _exports.lastValue = decoratorWithParams(function (target, key, descriptor) {
    let [taskName] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const {
      initializer
    } = descriptor;
    delete descriptor.initializer;
    if (_utils.USE_TRACKED) {
      return {
        get() {
          let lastInstance = this[taskName].lastSuccessful;
          if (lastInstance) {
            return lastInstance.value;
          }
          if (initializer) {
            return initializer.call(this);
          }
          return undefined;
        }
      };
    } else {
      let cp = (0, _object.computed)("".concat(taskName, ".lastSuccessful"), function () {
        let lastInstance = (0, _object.get)(this, "".concat(taskName, ".lastSuccessful"));
        if (lastInstance) {
          return (0, _object.get)(lastInstance, 'value');
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      });
      return cp(target, key, descriptor);
    }
  });
  const task = _exports.task = createDecorator(taskFromPropertyDescriptor);
  const dropTask = _exports.dropTask = createDecorator(taskFromPropertyDescriptor, {
    drop: true
  });
  const enqueueTask = _exports.enqueueTask = createDecorator(taskFromPropertyDescriptor, {
    enqueue: true
  });
  const keepLatestTask = _exports.keepLatestTask = createDecorator(taskFromPropertyDescriptor, {
    keepLatest: true
  });
  const restartableTask = _exports.restartableTask = createDecorator(taskFromPropertyDescriptor, {
    restartable: true
  });
  const taskGroup = _exports.taskGroup = createDecorator(taskGroupPropertyDescriptor);
  const dropTaskGroup = _exports.dropTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    drop: true
  });
  const enqueueTaskGroup = _exports.enqueueTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    enqueue: true
  });
  const keepLatestTaskGroup = _exports.keepLatestTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    keepLatest: true
  });
  const restartableTaskGroup = _exports.restartableTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    restartable: true
  });
});