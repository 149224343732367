define("ember-table/components/ember-tr/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.cells as |api|}}
    {{#if (has-block)}}
      {{#if this.isHeader}}
        {{yield (hash
          columnValue=api.columnValue
          columnMeta=api.columnMeta
  
          sorts=api.sorts
          sendUpdateSort=api.sendUpdateSort
  
          rowMeta=api.rowMeta
          rowsCount=api.rowsCount
  
          cell=(component "ember-th" api=api)
        )}}
      {{else}}
        {{yield (hash
          api=api
  
          cellValue=api.cellValue
          cellMeta=api.cellMeta
  
          columnValue=api.columnValue
          columnMeta=api.columnMeta
  
          rowValue=api.rowValue
          rowMeta=api.rowMeta
  
          rowsCount=api.rowsCount
  
          cell=(component "ember-td" api=api)
        )}}
      {{/if}}
    {{else if this.isHeader}}
      <EmberTh @api={{api}} />
    {{else}}
      <EmberTd @api={{api}} />
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "H9j8pjyp",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"cells\"]]],null]],null],null,[[[41,[48,[30,2]],[[[41,[30,0,[\"isHeader\"]],[[[1,\"      \"],[18,2,[[28,[37,5],null,[[\"columnValue\",\"columnMeta\",\"sorts\",\"sendUpdateSort\",\"rowMeta\",\"rowsCount\",\"cell\"],[[30,1,[\"columnValue\"]],[30,1,[\"columnMeta\"]],[30,1,[\"sorts\"]],[30,1,[\"sendUpdateSort\"]],[30,1,[\"rowMeta\"]],[30,1,[\"rowsCount\"]],[50,\"ember-th\",0,null,[[\"api\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,2,[[28,[37,5],null,[[\"api\",\"cellValue\",\"cellMeta\",\"columnValue\",\"columnMeta\",\"rowValue\",\"rowMeta\",\"rowsCount\",\"cell\"],[[30,1],[30,1,[\"cellValue\"]],[30,1,[\"cellMeta\"]],[30,1,[\"columnValue\"]],[30,1,[\"columnMeta\"]],[30,1,[\"rowValue\"]],[30,1,[\"rowMeta\"]],[30,1,[\"rowsCount\"]],[50,\"ember-td\",0,null,[[\"api\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"isHeader\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@api\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,8],null,[[\"@api\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]],[]]]],[1]],null]],[\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-th\",\"ember-td\"]]",
    "moduleName": "ember-table/components/ember-tr/template.hbs",
    "isStrictMode": false
  });
});