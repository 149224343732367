define("ember-concurrency/-private/external/task/task", ["exports", "ember-concurrency/-private/external/task/taskable", "ember-concurrency/-private/external/task-instance/executor"], function (_exports, _taskable, _executor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Task = void 0;
  class TaskLinkProxy {
    constructor(task, performType, linkedObject) {
      this.task = task;
      this.performType = performType;
      this.linkedObject = linkedObject;
    }
    perform() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return this.task._performShared(args, this.performType, this.linkedObject);
    }
  }
  class Task extends _taskable.Taskable {
    constructor(options) {
      super(options);
      this.perform = this._perform.bind(this);
    }
    linked() {
      let linkedObject = (0, _executor.getRunningInstance)();
      if (!linkedObject) {
        throw new Error("You can only call .linked() from within a task.");
      }
      return new TaskLinkProxy(this, _executor.PERFORM_TYPE_LINKED, linkedObject);
    }
    unlinked() {
      return new TaskLinkProxy(this, _executor.PERFORM_TYPE_UNLINKED, null);
    }
    _perform() {}
  }
  _exports.Task = Task;
});