define("ember-svg-jar/utils/make-helper", ["exports", "@ember/component/helper", "ember"], function (_exports, _helper, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeHelper;
  function makeHelper(helperFunc) {
    let helper;
    if (_helper.default && _helper.default.helper) {
      helper = _helper.default.helper(function (_ref, options) {
        let [assetId] = _ref;
        return helperFunc(assetId, options);
      });
    } else {
      helper = _ember.default.Handlebars.makeBoundHelper(function (assetId, options) {
        return helperFunc(assetId, options.hash || {});
      });
    }
    return helper;
  }
});