define("ember-concurrency/-private/task-group", ["exports", "ember-concurrency/-private/external/task/task-group", "ember-concurrency/-private/taskable-mixin", "ember-concurrency/-private/tracked-state"], function (_exports, _taskGroup, _taskableMixin, _trackedState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskGroup = void 0;
  class TaskGroup extends _taskGroup.TaskGroup {}
  _exports.TaskGroup = TaskGroup;
  if (_trackedState.TRACKED_INITIAL_TASK_STATE) {
    Object.defineProperties(TaskGroup.prototype, _trackedState.TRACKED_INITIAL_TASK_STATE);
  }
  Object.assign(TaskGroup.prototype, _taskableMixin.TASKABLE_MIXIN);
});